import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import {getData} from '../services/index';
import {postData} from '../services/index';
import {successMsg} from '../services/index';
import {errorMsg} from '../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, DatePicker, Form, Input, Modal, Select, Spin, Table, Tag, Tooltip, message,SelectProps, Drawer, Col, Row, Space, Divider  } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import dayjs from 'dayjs';
import { img_url } from '../services/index';
import { back_url } from '../services/index';

import './style.css';
import { aD } from '@fullcalendar/core/internal-common';
import { forEachChild } from 'typescript';
import layout from 'antd/es/layout';
import axios from 'axios';

let centre_code:any;
let adm_type:any;
let centre_name:any;
let staff_code:any;
let std_id:any;

type FieldType = {
    centre?: string;
    stdlist?:string;
  }

const { Option } = Select;
let options: any=[];
let std_data: any[];

interface DataType {
  sno?:number;
  cid?:number;
  name?:string;
  issue_id?: number;
  cert_id?: number;
  certi_no?: string;
  issue_date?:string;
  rec_date?:string;
  grade?:string;
  percent?:string;
  attend?:string;
  btn:number;
 
}

function Certificates() {
  
  const columns: ColumnsType<DataType> = [
    {
      title: '#',
      dataIndex: 'sno',
      key: 'sno',
    },
    {
      title: 'Certificate Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
        title: 'Certificate No.',
        dataIndex: 'certi_no',
        key: 'certi_no',
    },
    {
      title: 'Issue Date',
      dataIndex: 'issue_date',
      key: 'issue_date',
    },
    {
      title: 'Received Date',
      dataIndex: 'rec_date',
      key: 'rec_date',
    },
    {
      title: 'Attendence',
      dataIndex: 'attend',
      key: 'attend',
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      key: 'grade',
    },
    {
      title: 'Percentage',
      dataIndex: 'percent',
      key: 'percent',
    },
      
    {
      title: 'Actions',
      key: 'action',
      render: (_,{cid,issue_id,btn,name}) => (

          <> 
          <div className={centre_code == 'AVKSL1001'? '' : 'hidden'}>
          <a className={btn == 0 ? 'hidden' : ""}>
          <Tooltip title="Issue Certificate" >
            
            <Button type="primary" size='small' className='ml-1' onClick={()=>showDrawer(cid,name)} ><i className="fas fa-list"></i></Button>
         
          </Tooltip>
          </a>
         

            <a className={cid !=6 ? 'hidden':''} href={back_url+'printcertificate/'+ sid} target='_blank'>
            <Tooltip title="Print Certificate" className={btn == 1 ? 'hidden' : ''} >
              <Button  type="primary" className='ml-1' size='small'  ><i className="fas fa-award"></i></Button>
            </Tooltip>
            </a>
            <a className={cid !=6 ? 'hidden':''}  href={back_url+'printmarksheet/'+ sid} target='_blank'>
            <Tooltip title="Print Marksheet" className={btn == 1 ? 'hidden' : ''}>
              <Button  type="primary" className='ml-1' size='small'  ><i className="fas fa-book"></i></Button>
            </Tooltip>
            </a>
  
            <a className={btn == 1 ? 'hidden' : ''}>
            <Tooltip title="Edit Certificate Details"  >
              <Button  type="primary" className='ml-1' size='small' onClick={()=>showDrawerEdit(issue_id)}  danger><i className="fas fa-edit"></i></Button>
            </Tooltip>
            </a>
            
            </div>
            
          </>
      ),
      
    },
  ];
  const [centreData, setCentreData] = useState([]);
  const [stdData, setStdData] = useState([]);
  const [certData, setCertData] = useState([]);
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setElement] = useState();
  const [ishide, setHide] = useState(true);
  const [std_name, setName]= useState("");
  const [std_mobile,setMobile] = useState("");
  const [cert_name,setCName] = useState("");
  const [sid, setId]= useState("");
  const [std_course, setCourse]= useState("");
  const [std_img, setImg]= useState("");
  const [open, setOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const [isChide, setChide] = useState(false);
  const {id} = useParams();

  const showDrawer = (cid:any,name:any)=> {
    //alert(cid);
    form.setFieldValue('certi_id',cid);
    form.setFieldValue('std_id',sid);
    setCName(name);
    getCertDetails(cid,sid);
    setOpen(true);
    
    
  };

  const showDrawerEdit = (id:any) =>{
    setLoading(true);
    //alert(id);
    getData('geteditcert/'+id).then((res)=>{
      //console.log(res.data['certi_no']);
      setLoading(false);
      formEdit.setFieldValue('certi_no',res.data['certi_no']);
      var di = new Date(res.data['issue_date']);
    ///  console.log(di);
      var ndi = moment(di.toString()).format('DD-MM-YYYY');
      //console.log(ndi); 
     // var ndi = dayjs(res.data['issue_date'], 'DD-MM-YYYY');
      //console.log(ndi);
      formEdit.setFieldValue('issue_date',dayjs(ndi, 'DD-MM-YYYY'));

      if(res.data['rec_date'] != null){
        var dr = new Date(res.data['rec_date']);
       // console.log(di);
        var ndr = moment(dr.toString()).format('DD-MM-YYYY');
       // console.log(ndr); 
      // var ndi = dayjs(res.data['issue_date'], 'DD-MM-YYYY');
      //  console.log(ndr);
        formEdit.setFieldValue('rec_date',dayjs(ndr, 'DD-MM-YYYY'));
      }

      
      formEdit.setFieldValue('attend',res.data['attend']);
      formEdit.setFieldValue('percent',res.data['percent']);
      formEdit.setFieldValue('grade',res.data['grade']);
      formEdit.setFieldValue('certi_id',res.data['certi_id']);
      formEdit.setFieldValue('std_id',res.data['std_id']);
      formEdit.setFieldValue('issue_id',res.data['issue_id']);
      
    })
    setEditOpen(true);
  }

  const getCertDetails = (cid:any,sid:any) =>{
    if(cid == 6 || cid == 4){
      getData('getstdcertidetails/'+cid+'/'+sid).then((res)=>{
      
        if(res.data['success'] == true){
          if(cid == 6){
            form.setFieldValue('certi_no',res.data['cno']);
          }
          form.setFieldValue('attend',res.data['attend']);
          form.setFieldValue('percent',res.data['percent']);
          form.setFieldValue('grade',res.data['grade']);
        }else{
          message(res.data['msg']);
          onClose();
        }
        
      })
    }else{
      getData('getattendper/'+sid).then((res)=>{
      
        form.setFieldValue('attend',res.data);
      
      
      })
    }
    
  }

  const message = (msg:any) => {
    Modal.warning({
      title: 'Warning',
      content: msg,
    });
  };

  const onClose = () => {
    setOpen(false);
    setEditOpen(false);
    
  };

  const loadCentre = ()=>{
    getData('getcentres/').then((res)=>{
       
      setCentreData(res.data)
      
    });
  }

  const loadStdList = ()=>{
    // console.log(centre_code);
    getData('getStdlist/'+String(centre_code)).then((res)=>{
        options = res.data;
        setStdData(res.data);
        
       // console.log(stdData);
    })
   }

   const handleCentreChange = (value: string) => {
        centre_code = value;
        loadStdList();
    }

   

    const handleChange = (value: string) => {
        std_id = value;
        setLoading(true);
        loadData();
    }

   

    const loadData = () =>{
        
      getData('getstndcertificates/'+String(std_id)).then((res)=>{
          setHide(false);
          //setElement(res.data);
          //console.log(res.data['std_data'].std_id);

          setName(res.data['std_data'].std_name);
          setId(res.data['std_data'].std_id);
          setCourse(res.data['std_data'].course_name);
          setImg(res.data['std_data'].std_img);
          setMobile(res.data['std_data'].std_mobile);
          setCertData(res.data['cert_data']);
         
          setLoading(false);
         //console.log(std_name+","+std_mobile);
      })
  }

  const onFormSubmitEdit = useCallback(async () => {
    formEdit
            .validateFields()
            .then(() => {
              setLoading(true);
              let value = formEdit.getFieldsValue();
              let formData = new FormData();
              formData.append("issue_id",value.issue_id);
              formData.append("certi_id",value.certi_id);
              formData.append("certi_no",value.certi_no);
              formData.append("std_id",value.std_id);
              formData.append("issue_date",moment(value.issue_date.toString()).format('YYYY-MM-DD'));
              //console.log(value.rec_date.toString());
              if(value.rec_date == null ){
                //alert("ok");
                formData.append("rec_date","");
              }else{
                formData.append("rec_date",moment(value.rec_date.toString()).format('YYYY-MM-DD'));
              }
              formData.append("grade",value.grade);
              formData.append("percent",value.percent);
              formData.append("attend",value.attend);
              postData('updatecerti',formData).then((res)=>{
                // console.log(res.data['success']);


                 setLoading(false);
                  //console.log(res.data); 
                 if(res.data == 1){
                   successMsg('Certificate Deatils Updated Successfully');
                   formEdit.resetFields();
                   loadData();
                   onClose();
                 }else{
                   errorMsg('Error, Try Again');
                 }
         
                 
               })
              
            })
            
  }, [formEdit])

  const onFormSubmit = useCallback(async () => {
    form
      .validateFields()
      .then(() => {
        // Validation is successful
        setLoading(true);
        let value = form.getFieldsValue(); 
        let formData = new FormData();
        formData.append("certi_id",value.certi_id);
        formData.append("certi_no",value.certi_no);
        formData.append("std_id",value.std_id);
        formData.append("issue_date",moment(value.issue_date.toString()).format('YYYY-MM-DD'));
        //console.log(value.rec_date.toString());
        if(value.rec_date == null ){
          //alert("ok");
          formData.append("rec_date","");
        }else{
          formData.append("rec_date",moment(value.rec_date.toString()).format('YYYY-MM-DD'));
        }
        formData.append("grade",value.grade);
        formData.append("percent",value.percent);
        formData.append("attend",value.attend);
       postData('savecerti',formData).then((res)=>{
       // console.log(res.data['success']);
        setLoading(false);
        
        if(res.data['success'] == 1){
          successMsg('Certificate Issued Successfully');
          form.resetFields();
          loadData();
          onClose();
          console.log(res.data['std_name'],res.data['mobile'],res.data['cname']);
          sendMSG(res.data['std_name'],res.data['mobile'],res.data['cname']);
        }else{
          errorMsg('Error, Try Again');
        }

        
      })

      })
      .catch((errors) => {
        // Errors in the fields
        console.log(errors);
        errorMsg("Error, Try Again!!")
        //errorMsg('Error Try Again');
      });
  }, [form]);

  const sendMSG = async (std_name: any, mobile:any,cname:any) => {
    console.log(std_name,mobile,cname);
    var message = encodeURI("Aranyak Institute \nDear,"+ std_name + "\n We have issued your " + cname +" certificate. Kindly collect your Certificate from the center after 7 days.") ;


 await axios.get("https://web.cloudwhatsapp.com/wapp/api/send?apikey=a3f421f32a564cc897a22df74374ee7e&mobile="+ mobile +"&msg=" + message).then((res)=>{
  console.log(res);
   return true;
 })
}

  useEffect(()=>{
    let login = JSON.parse(String(localStorage.getItem('login')));
   // console.log(login);
    centre_code = login.centre_code;
    adm_type = login.adm_type;
    centre_name = login.centre_name;
    staff_code = login.staff_code
    std_id = 0;
   // console.log(centre_code);
    loadCentre();
    form.setFieldValue('centre',centre_code);
    form.setFieldValue('date',dayjs(Date().toString()));
    loadStdList();

    if(id != '0'){
      handleChange(atob(String(id)));
      setChide(true);
    }
    
   // setLoading(true);

    
    //loadAttend();
   },[]);
  

  return (
    <div className='Certificates'>
        <div className="content-header mt-5">
        <div className={isChide? 'hidden':''}>
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Certificates</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
              <div className="row mb-2">
               
                <div className=" col-sm-12 d-flex">
                <Form form={form} layout='inline'>
                   <Form.Item<FieldType> 
                       
                       name="centre"
                       className="d-flex"
                       >
                     <Select placeholder="Select Centre"   className='flex-fill' style={{ width: 400 }} onChange={handleCentreChange} >
                         {
                         adm_type == 1 ? (
                             centreData.map((item:any)=>{
                                 return(
                                           <Select.Option value={item.centre_code}>{item.centre_name}</Select.Option>
                                       
                                     )       
                                 })
                             ) : (
                                 <Select.Option  value={centre_code}>{centre_name}</Select.Option>
                             )
                         }
                     
                     </Select>
                     </Form.Item>
                     <Form.Item<FieldType> 
                       
                       name="stdlist"
                       
                       >
                     <Select placeholder="Select Name"    className='flex-fill' 
                        
                        showSearch
                        style={{ width: 400 }}
                        onChange={handleChange}
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').toString().includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toString().toUpperCase().localeCompare((optionB?.label ?? '').toString().toUpperCase())
                        }
                        options={options}

                     >
                     
                     </Select>
                     </Form.Item>
                     
                     </Form>
                </div>{/* /.col */}
               
                
              </div>{/* /.row */}
              
            </div>{/* /.container-fluid */}
            </div>
          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                    <Spin tip="Loading" size="large" spinning={loading}>
                     <div className={ ishide ? 'hidden':'' }>
                        <Card className='font-style' title="Student Certificate List"  >
                        <div className={isChide ? 'hidden' : ''}>
                            <div className="row">
                                <div className="col-6">
                                    <h3>Student ID: {sid}</h3>
                                    <h3>Name: {std_name}</h3>
                                    <h3>Course: {std_course}</h3>
                                </div>
                                <div className="col-6">
                                    <img width="180" height="200" className="img-thumbnail d-block mx-auto" src={img_url+std_img} alt="" />
                                </div>
                              </div>
                            </div>
                            <Table className='font-style' columns={columns} dataSource={certData} size="small" />
                          
                        </Card>
                      </div>
                      </Spin>
                    
                    </div>
                </div>
              </div>
            </section>
            <Drawer
              title="Issue Certificate"
              width={520}
              onClose={onClose}
              open={open}
              zIndex={1000}
              extra={
                <Space>
                  <Button onClick={onClose}>Cancel</Button>
                  <Button onClick={onFormSubmit} type="primary">
                    Submit
                  </Button>
                </Space>
              
                }
                style={{
                  marginTop:50,
                }}
            
          >
      <Spin tip="Loading" size="large" spinning={loading}>
        
        <Form layout="vertical"  form={form} autoComplete="off">
        <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="certi_no"
                label="Ceritificate Number"
                rules={[{ required: true, message: 'Please enter certificate number' }]}
              >
                <Input placeholder="Please enter certificate number"  />
              </Form.Item>
            </Col>
            
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="issue_date"
                label="Issue Date"
                rules={[{ required: true, message: 'Please Issue Date' }]}
              >
                <DatePicker style={{width:"100%"}}  format={'DD-MM-YYYY'}/>
              </Form.Item>
            </Col>
            
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="rec_date"
                label="Recieved Date"
                
              >
                <DatePicker style={{width:"100%"}}  format={'DD-MM-YYYY'}/>
              </Form.Item>
            </Col>
            
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="attend"
                label="Attendance"
                rules={[{ required: true, message: 'Please enter attendance percentage' }]}
              >
                <Input placeholder="Please enter attendance percentage"  />
              </Form.Item>
            </Col>
            
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="percent"
                label="Percentage"
                rules={[{ required: true, message: 'Please enter percentage' }]}
              >
                <Input placeholder="Please enter percentage"  />
              </Form.Item>
            </Col>
            
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="grade"
                label="Grade"
                rules={[{ required: true, message: 'Please enter Grade' }]}
              >
                <Input placeholder="Please enter Grade"  />
              </Form.Item>
            </Col>
            
          </Row>

          <Row gutter={16} className='hidden'>
            <Col span={24}>
              <Form.Item
                name="certi_id"
                label=""
                rules={[{ required: true, message: 'Please enter Grade' }]}
              >
                <Input placeholder="Please enter Grade"  />
              </Form.Item>
            </Col>
            
          </Row>
          <Row gutter={16} className='hidden'>
            <Col span={24}>
              <Form.Item
                name="std_id"
                label=""
                rules={[{ required: true, message: 'Please enter Grade' }]}
              >
                <Input placeholder="Please enter Grade"  />
              </Form.Item>
            </Col>
            
          </Row>
          
         
            
          
        </Form>
        
        </Spin>
        <br/>
      </Drawer>

      <Drawer
              title=" Edit Certificate"
              width={520}
              onClose={onClose}
              open={openEdit}
              zIndex={1000}
              extra={
                <Space>
                  <Button onClick={onClose}>Cancel</Button>
                  <Button onClick={onFormSubmitEdit} type="primary">
                    Submit
                  </Button>
                </Space>
              
                }
                style={{
                  marginTop:50,
                }}
            
          >
      <Spin tip="Loading" size="large" spinning={loading}>
        
        <Form layout="vertical"  form={formEdit} autoComplete="off">
        <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="certi_no"
                label="Ceritificate Number"
                rules={[{ required: true, message: 'Please enter certificate number' }]}
              >
                <Input placeholder="Please enter certificate number"  />
              </Form.Item>
            </Col>
            
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="issue_date"
                label="Issue Date"
                rules={[{ required: true, message: 'Please Issue Date' }]}
              >
                <DatePicker style={{width:"100%"}}  format={'DD-MM-YYYY'}/>
              </Form.Item>
            </Col>
            
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="rec_date"
                label="Recieved Date"
                
              >
                <DatePicker style={{width:"100%"}}  format={'DD-MM-YYYY'}/>
              </Form.Item>
            </Col>
            
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="attend"
                label="Attendance"
                rules={[{ required: true, message: 'Please enter attendance percentage' }]}
              >
                <Input placeholder="Please enter attendance percentage"  />
              </Form.Item>
            </Col>
            
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="percent"
                label="Percentage"
                rules={[{ required: true, message: 'Please enter percentage' }]}
              >
                <Input placeholder="Please enter percentage"  />
              </Form.Item>
            </Col>
            
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="grade"
                label="Grade"
                rules={[{ required: true, message: 'Please enter Grade' }]}
              >
                <Input placeholder="Please enter Grade"  />
              </Form.Item>
            </Col>
            
          </Row>

          <Row gutter={16} className='hidden'>
            <Col span={24}>
              <Form.Item
                name="certi_id"
                label=""
                rules={[{ required: true, message: 'Please enter Grade' }]}
              >
                <Input placeholder="Please enter Grade"  />
              </Form.Item>
            </Col>
            
          </Row>
          <Row gutter={16} className='hidden'>
            <Col span={24}>
              <Form.Item
                name="std_id"
                label=""
                rules={[{ required: true, message: 'Please enter Grade' }]}
              >
                <Input placeholder="Please enter Grade"  />
              </Form.Item>
            </Col>
            
          </Row>
          <Row gutter={16} className='hidden'>
            <Col span={24}>
              <Form.Item
                name="issue_id"
                label=""
                rules={[{ required: true, message: 'Please enter Grade' }]}
              >
                <Input placeholder="Please enter Grade"  />
              </Form.Item>
            </Col>
            
          </Row>
          
         
            
          
        </Form>
        
        </Spin>
        <br/>
      </Drawer>
    </div>
  )
}

export default Certificates